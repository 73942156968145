import React, { useEffect, useRef, useState } from 'react'
import { getMapLocationList, getrobotStatus, MapDetails, robotListfleetId } from '../API/index'
import { useSelector } from 'react-redux';
import * as d3 from "d3";
import { RobotIdAction } from '../redux/actions';
import { useDispatch } from 'react-redux';
import $ from 'jquery';

const L = window["L"]
function Maps(props) {
  const bounds = [
    [-3000, -3000],
    [3000, 3000]
  ];
    const token = localStorage.getItem('token')
    const [mapUrl,setmapurl]= useState()
    var MapId =''
    const [XCoordinate, setXCoordinate] =useState(0)
    const [YCoordinate, setYCoordinate] =useState(0)
    const [baseUrl, setbaseUrl] =useState()
    const [locationList, getLocationList]=useState()
    var robotlist =[]
    const [mapWidth, setmapwidth] =useState(0)
    const [mapHeight, setmapHeight] =useState(0)
 
//  const fleetId=localStorage.getItem('fleetId')
// console.log('Props.fleetIdProps',props.fleetId)
 let fleetId= props.fleetId
 const [imagestate, setimagestate] = useState({
  backgroundImage: `url(${baseUrl})`,
  backgroundPosition: '0% 0%'})
 const robotsid = useSelector(state=> state.robotidReducer)
 let mapWi=''
 let mapHe=''
 const dispatch= useDispatch()
 useEffect(()=>{

  setInterval(()=>{
     robotListfleetId(props?.fleetId, token).then((res)=>{
      
      
      createmap(locationList,res?.data?.data)
      // robotlist.push(robolist)
     
    //  console.log('RobotStatusFleetId', res?.data?.data)
    //  console.log('RobotListRobotlist',robotlist)
    //  dispatch(RobotIdAction(res?.data?.data))    
    })
    .catch((err)=>{
     console.log('Error in robotMap' ,err)
    })},
    10000)     
    },[locationList])

const createmap=(locationList,robotlist)=>{
//   console.log('locationListlocationList',locationList,robotlist)
  const xSize = mapWidth; 
  const ySize = mapHeight;
  const margin = 20;
  const xMax = xSize - margin*2;
  const yMax = ySize - margin*2;
  console.log('LocationList robotlist',locationList,robotlist)
  // Create Random Points
  var data=[];
  robotlist?.map((item)=>{
    locationList?.push({
      metricPositionX: item.lat,
      metricPositionY: item.log,
      homeRobot: null,
      imageLocationUrl: null,
      locationId: null,
      locationName: null,
      locationType: "ROBOT",
      mapId: null,
      metricPositionYaw: item.metricPositionYaw,
      positionX: item.lat,
      positionY: item.lon,
      positionYaw: item.positionYaw,
      status: null,
      wanderLoc: 0
    })
  })
  data?.push(locationList)
//   console.log('datadata',data)
  // for (let i = 0; i < locationList?.length; i++) {
  //   data.push([Math.random() * xMax, Math.random() * yMax]);
  // }
  
  // Append SVG Object to the Page
  const svg = d3.select("#myPlot")
    .append("svg")
    // .append("g")
    .attr("transform","translate(" + margin + "," + margin + ")")
    // .attr('width',`${mapWidth/4}`)
    // .attr('height',`${mapHeight/4}`)
  
  // X Axis
  const x = d3.scaleLinear()
    .domain([0, mapWidth])
    .range([0, xMax]);
  
  svg
    .append("g")
    .attr("transform", "translate(0," + yMax + ")")
    .call(d3.axisBottom(x));
  
  // Y Axis
  const y = d3.scaleLinear()
    .domain([0, mapHeight])
    .range([ yMax-30, 0]);
  
  svg
  // append("g")
    .call(d3.axisLeft(y))
    
  console.log('DataData',data)
  // Dots
  svg.append('g')
    .attr('transform', "translate(" + -15 + "," + -20 + ")")
    .selectAll("dot")
    .data(data[0]).enter()
    .append("image")    
    .attr('xlink:href', function (d,i) { 
                                  if(d.locationType==="PICKUP"){
                                        return '/assets/images/PickUpLocation-icon (1).svg'
                                    }
                                  else if(d.locationType==="HOME")  {
                                    return '/assets/images/RobotHomeLocation-icon (1).svg'
                                    }
                                  else if(d.locationType==="ROBOT"){
                                    return '/assets/images/robot-icon (1).svg'
                                   }
                                  else {
                                    return '/assets/images/DropLocation-icon (1).svg'
                                 }})
  
    .attr('width', 20)
    .attr('height', 20)
    .attr("x", function (d,i) { return (parseInt(d.lat)) } )
    .attr("y", function (d,i) { return (parseInt(d.lon)) } )
    
}
 useEffect(()=>{
  let mapdata;

  // console.log('fleetIdfleetId',fleetId)
//  setInterval(()=>{
  MapDetails(fleetId , token).then((res)=>{
     console.log('Map Details Response', res, fleetId)
     mapdata=res
     setXCoordinate(mapdata.data.data.mapOrigionX)
     setYCoordinate(mapdata.data.data.mapOrigionY)
     setbaseUrl(mapdata.data.data.baseMapImageUrl)
     localStorage.setItem('mapid', mapdata.data.data.mapId )  
    //  console.log('mapdata',mapdata)
     let mapW =mapdata.data.data.mapWidth
     setmapwidth(mapW)
     let mapH=mapdata.data.data.mapHeight
     setmapHeight(mapH)
     setmapurl({...mapUrl,mapdata})
  })
  .catch((err)=>{
//    console.log('Error', err)
  })
//  },2000)
  let mapID=localStorage.getItem('mapid')
  // setInterval(()=>{
//   console.log('fleetIdfleetIdfleetId',fleetId)
  getMapLocationList(fleetId, token).then((res)=>{
      console.log("Map Location List" , res)
      getLocationList(res.data.data)
  }).catch((err)=>{ 
    //   console.log('Error Location List', err)
  })
// },2000)
  mapWi=parseInt(mapWidth)
  mapHe=parseInt(mapHeight)

  // const contains = mapRef.current.leafletElement
  // .getBounds()
  // .contains(center);
 },[])
  const position = [XCoordinate,YCoordinate]
  const mapRef = useRef();
  const [tankR, setTankR] = useState(0);
  const center = [51.505, -0.09]





  $(document).on('mousemove', '.frame', function(event){
   
    var element = {
      width: $(this).width(),
      height: $(this).height()
    };
    
    var mouse = {
      x : event.originalEvent.pageX,
      y : event.originalEvent.pageY
    };
    
    var offset = $(this).offset();
    
    var origin = {
      x: (offset.left+(element.width/2)),
      y: (offset.top+(element.height/2))
    };
    
    var trans = {
      left: (origin.x - mouse.x)/2,
      down: (origin.y - mouse.y)/2
    };
    // console.log('mouse',mouse,'element',element,'trans',trans)
    var transform = ("scale(3,3) translateX("+ trans.left +"px) translateY("+ trans.down +"px)");
    $(this).children(".zoom").css("transform", transform);
    
  });
  
  $(document).on('mouseleave', '.frame', function(){
    $(this).children(".zoom").css("transform", "none");
  });
  

  return (
   
    <div className="Map-Main-Whole-wrapper border-blue-top">
          {/* <div class="mapouter"><div class="gmap_canvas">
              <iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={mapUrl}>
             </iframe>
            <a href="https://mcpenation.com/">Minecraft Website</a>
            </div><style></style></div> */}
            {/* {console.log('Map Data Dtaa',mapUrl)}
            {console.log('baseUrl',baseUrl)} */}
            {console.log('BaseURLBaseURL',baseUrl)}
            <figure className="frame">
                <svg id="myPlot" className='zoom' style={{width:`${mapWidth/10}`,height:`${mapHeight/10}`, backgroundImage:`url(${baseUrl})`, backgroundSize:"contain" , backgroundPosition:'0% 0%', backgroundRepeat: 'no-repeat'}}></svg>
           </figure>
        </div>
  )
}

export default React.memo(Maps)