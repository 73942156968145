import { useSelect } from '@mui/base';
import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useSelector } from 'react-redux';
import { iconPerson } from './IconMarker'
function Maptwo(props) {
   
    const [interval,setinterval] =useState(false)

       var latitude=useSelector(state=>state.latitudeReducer)
       var longitude = useSelector(state=> state.longitudeReducer)
    useEffect(()=>{

    },[props.longitude,props.latitude,interval])
  return (
 <>
   {console.log('latitudelatitude',latitude.latitude,'logitudelogitude',longitude.longitude)}
   {latitude.latitude === NaN || longitude.longitude===NaN ?  
    <MapContainer center={[35,25]} zoom={10} scrollWheelZoom={true} style={{height:'300px'}}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
   />
   {(()=>{  
                   
   })()}
          <Marker position={[35,25]} icon={iconPerson}>
             {console.log('lati',props.latitude,'longi',props.longitude)} 
           <Popup>
           </Popup>
       </Marker>
</MapContainer> :
 <MapContainer center={[latitude,longitude]} zoom={10} scrollWheelZoom={true} style={{height:'300px'}}>
<TileLayer
  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
/>
{(()=>{
   
})()}
  { console.log('inside Inside')}
    <Marker position={[latitude, longitude]} icon={iconPerson}>
       {console.log('lati',props.latitude,'longi',props.longitude)} 
      <Popup>
      </Popup>
   </Marker>
</MapContainer>}
</>
  )
}

export default React.memo(Maptwo)