import React, { useEffect, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import '../App.css'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Battery80Icon from '@mui/icons-material/Battery80';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { FormControlLabel, Switch } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { Autorunplay, changeheadlightStatus, changeringlightStatus, changeRobotWanderStatus, closedoorone, closedoortwo, DisengageMotors, EmergencyStop, getrobotStatus, handleteleopAck, handleteleopAckinterrupt, handleteleopComplete, opendoorone, opendoortwo, Openssh, pauserobot, playrobot, ReleaseEmergencyStop, resetDiagnostics, RobotTestingMode, sendhome, startViewer, stopViewer, teleopComplete } from '../API';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import DisengageMotorsModal from '../subcomponents/DisengageMotorsModal';
import OpenSSHModal from '../subcomponents/OpenSSHModal';
import AutorunMoving from '../subcomponents/AutorunMoving'
import Maps from '../subcomponents/Maps';
import { toast , ToastContainer } from 'react-toastify';
import RobotActions from '../subcomponents/RobotActions';
import TeleopCompleted from '../subcomponents/TeleopCompleted';
import TeleopAck from '../subcomponents/TeleopAck';
import { useSelector } from 'react-redux';
import MapLeaflet from '../subcomponents/MapLeaflet';

function Dashboard() {

  const [robotData, setrobotData] = useState()
  const navigate =useNavigate()
  const isLogged = localStorage.getItem('isLoggedin')
  const robotID= useLocation()
  const token= localStorage.getItem('token')
  const [playbtn, setplaybtn] =useState(false)
  const [googleMap, setgoogleMap] =useState()
  // const [viewerState, setViewerState] = useState(false)
  // console.log('RobotIDRobotID',robotID)
  const [runstate, setrunstate] =useState(false)
  let fleetId  = robotID?.state?.name?.fleetId
  let robotId= robotID?.state?.name?.robotId
  localStorage.setItem('robotId', robotId)
  const [isstop, setisStop] =useState()
  const DiagnoticsResetfialed=()=> toast.error('Reset Diagnostics Failed')
  const ResetDiagnosticsSuccess=()=>toast.success('Reset Diagnostics Success')
  const sendHomeError=()=> toast.error('Send Home Failed')
  const sendHomeSuccess=()=>toast.success('Send Home Success')
  const WanderModeError=()=> toast.error('Wander Mode Failed')
  const WanderModeSuccess=()=>toast.success('Wander Mode Successfully Changed')
  const ringlightError=()=> toast.error('Ring Light Failed')
  const ringlightSuccess=()=>toast.success('Ring Light Successfully Changed')
  const headlightError=()=> toast.error('Head Light Failed')
  const headlightSuccess=()=>toast.success('Head Light Successfully Changed')
  const EmergencyStopError=()=> toast.error('Emergency Stop Failed')
  const EmergencyStopSuccess=()=>toast.success('Emergency Stop Successfully')
  const ReleaseStopError=()=> toast.error('Release Stop Failed')
  const ReleaseStopSuccess=()=>toast.success('Release Stop Successfully')
  const OpenDoor1Error=()=> toast.error('OpenDoor1 Failed')
  const OpenDoor1Success=()=>toast.success('OpenDoor1 Successfully')
  const OpenDoor2Error=()=> toast.error('OpenDoor2 Failed')
  const OpenDoor2Success=()=>toast.success('OpenDoor2 Successfully')
  const CloseDoor1Error=()=> toast.error('CloseDoor1 Failed')
  const CloseDoor1Success=()=>toast.success('CloseDoor1 Successfully')
  const CloseDoor2Error=()=> toast.error('CloseDoor2 Failed')
  const CloseDoor2Success=()=>toast.success('CloseDoor2 Successfully')
  const playError=()=> toast.error('Play Failed')
  const playSuccess=()=>toast.success('Play Successfully')
  const pauseError=()=> toast.error('Pause Failed')
  const pauseSuccess=()=>toast.success('Pause Successfully')

  const sshEndPoint = useSelector(state=>state.sshendpointReducer)
  var latitude = 38.89
  var logitude=-77.03
  useEffect(()=>{
    if(isLogged==='LoginFalse'){
      window.location.replace('/')
    }
    else{
    // const robotId=localStorage.getItem('robotId')
   setInterval(()=>{
    getrobotStatus(robotId,token)
    .then((res)=>{
      // console.log('GetRobotStatus response', res)
      setrobotData(res?.data?.data)
    })
    .catch((err)=>{
      console.log('Error in Robot Data', err)
    })
   },10000) 
   setTimeout(()=>{
    setState({
      wandermode:Boolean(robotData?.robotStatus?.wanderMode)
    })
   },10000)
  
  }
  },[])

    const [state, setState] = React.useState({
      testingmode: false,
      wandermode: false,
      ringlight:false,
      headlight:false
     })
    // const handletestingChange = (event) => {
    //     setState({
    //       ...state,
    //       [event.target.name]: event.target.checked,
    //     });

    //       RobotTestingMode(robotId,token).then((res)=>{
    //          console.log('Testing Mode Response',res)
    //         }
    //       ).catch((err)=>{
    //         console.log('Testing Mode Error', err)
    //       })
    //   };
    const handlewanderChange=(event)=>{
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
      changeRobotWanderStatus(robotId, token , state.wandermode)
      .then((res)=>{
            // console.log('Change Robot Wander Status Response', res)
            WanderModeSuccess()
      })
      .catch((error)=>{
            // console.log('Change Robot Wander Status Error', error)
            WanderModeError()
      })
    }  
    const handleringlightChange=(event)=>{
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
     
      changeringlightStatus(robotId, token , state.ringlight)
      .then((res)=>{
            // console.log('Change Robot Wander Status Response', res)
            ringlightSuccess()
      })
      .catch((error)=>{
            // console.log('Change Robot Wander Status Error', error)
            ringlightError()
      })
    }
    const handleheadlightchange=(event)=>{
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
      changeheadlightStatus(robotId, token , state.headlight)
      .then((res)=>{
            // console.log('Change Robot Wander Status Response', res)
            headlightSuccess()
      })
      .catch((error)=>{
            // console.log('Change Robot Wander Status Error', error)
            headlightError()
      })
    }
    
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 36,
        height: 16,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#000',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? 'rgb(189,196,224)' : 'rgb(189,196,224)',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 12,
          height: 12,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#000' : 'rgb(189,196,224)',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
         color:'rgb(189,196,224)',
         border:'2px solid rgb(189,196,224)' 
        },
      }));
    const handlelogout=()=>{
      navigate('/')
      localStorage.setItem('useremail',null)
      localStorage.setItem('password',null)
      localStorage.setItem('token', null)
      localStorage.setItem('fleetID', null)
      localStorage.setItem('robotId',null)
      localStorage.setItem('Isloggedin', "LoginFalse")
    } 
    const handlesendHome=(robotId)=>{
      sendhome(robotId, token)
      .then(res=>{
          // console.log("SendHomeResponse", res)
          sendHomeSuccess()
       })
      .catch((err)=>{
          //  console.log('SendHomeError', err)
           sendHomeError()
      })
    } 
    // const handleStartViewer =(robotId)=>{
    //   startViewer(robotId, token)
    //   .then((res)=>{
    //      console.log('Start Viewer Response', res)
    //      setViewerState(true)
    //   })
    //   .catch((err)=>{
    //      console.log('Error In start Viewer', err)  
    //      setViewerState(false)

    //     })
      
    // }
    // const handleStopViewer=()=>{
    //   stopViewer(robotId, token)
    //   .then((res)=>{
    //     console.log('stop Viewer Response', res)
    //     setViewerState(false)
    //  })
    //  .catch((err)=>{
    //     console.log('Error In Stop Viewer', err)  
    //     setViewerState(true)
    //    })
    // }
    const handleResetDiagnostics =(robotId)=>{
      resetDiagnostics(robotId, token)
      .then((res)=>{
        //  console.log('Reset Diagnotics Response', res)
         ResetDiagnosticsSuccess()
      })
      .catch((err)=>{
        //  console.log('Reset Diagnotics Error', err)  
         DiagnoticsResetfialed()
       })
    }
  
  const handleOpendoorone=(robotId)=>{
    opendoorone(robotId, token)
    .then((res)=>{
    //  console.log("Open Door 1 Response", res)
      OpenDoor1Success()
    })
    .catch((err)=>{
    //  console.log('Open Door 1 Error',err)   
      OpenDoor1Error()
    })
   }
  const handleOpendoortwo=(robotId)=>{
    opendoortwo(robotId, token)
    .then((res)=>{
      // console.log("Open Door 2 Response", res)
      OpenDoor2Success()  
    })
    .catch((err)=>{
    //  console.log('Open Door 2 Error',err)   
      OpenDoor2Error()
    })
   }
   const handleClosedoorone=(robotId)=>{
    closedoorone(robotId, token)
    .then((res)=>{
    //  console.log("Close Door 1 Response", res)
     CloseDoor1Success()
    })
    .catch((err)=>{
    //  console.log('Close Door 1 Error',err)   
     CloseDoor1Error()
    })
   }
  const handleClosedoortwo=(robotId)=>{
    closedoortwo(robotId, token)
    .then((res)=>{
      // console.log("Close Door 2 Response", res)
      CloseDoor2Success()  
    })
    .catch((err)=>{
    //  console.log('Close Door 2 Error',err)   
     CloseDoor2Error()
    })
   } 
  const handlebackbutton=()=>{
    navigate(-1)
  }
    
 const handleStop=(robotId)=>{
  EmergencyStop(robotId,token)
  .then((res)=>{
    // console.log('Emergency Stop Response',res)
    EmergencyStopSuccess()
    setisStop(true)
  })
  .catch((err)=>{
    // console.log('Emergency Stop Error',err)
    EmergencyStopError()
    setisStop(false)
  })
 } 
 const handleRelease=(robotId)=>{
 ReleaseEmergencyStop(robotId, token)
 .then((res)=>{
  // console.log('Release Emergency Stop Response', res)
  ReleaseStopSuccess()
  setisStop(false)
 })
 .catch((err)=>{
  // console.log('Release Emergency Stop Response', err)
  ReleaseStopError()
  setisStop(true)
 })  
 }
const handlePauseIcon=(robotId)=>{
 pauserobot(robotId, token)
 .then((res)=>{
  // console.log('Pause Button Response', res)
  pauseSuccess()
  setplaybtn(true)
  })
 .catch((err)=>{
  // console.log('Pause Button Error' , err)
  pauseError()
 })
}
const handlePlayIcon=(robotId)=>{
  playrobot(robotId, token)
  .then((res)=>{
    //  console.log('Play Button Response', res)
     playSuccess()
   })
   .catch((err)=>{
    //  console.log('Play Button Error', err)
     playError()
   })
}




  return (
    <> 
 <div className="container-fluid DashBoard-page-Main-Whole-wrapper">
      {/* {console.log('Boolean(robotData?.robotStatus?.wandermode)',Boolean(robotData?.robotStatus?.wanderMode))} */}
{/* 
      {console.log('robotIDrobotID',robotID)} */}
      <div className='row' style={{paddingLeft: "27px",marginBottom: '5rem'}}> 
         <div className='col-md-3 dashboard-left-side-div-wrapper-Main'> 
           <div className="dashboard-left-side-div-one-wrapper"> 
                <h3> 
                <span style={{cursor:'pointer'}} onClick={handlebackbutton}><ArrowBackIosNewIcon style={{color:'#b1b1b1' , fontSize:'37px'}}/></span>
                 {robotData?.robotStatus?.robotId}
                 
                </h3>
                <div className="dashboard-left-side-div-one-inner-div"> 
                   {robotData?.robotStatus?.robotStatus==="AVAILABLE" ?  
                   <span className="text-left m-0 p-color-green">Available</span>: <span className="text-left m-0 p-color-red"> UnAvailable</span> }
                    <div>
                    <div className="dashboard-left-side-div-one-wrapper-battery-div">
                      <Battery80Icon style={{color:'rgb(113, 207, 98)' , transform:'rotate(90deg)', fontSize:'13px'}}/>
                      <p>{parseInt(robotData?.robotStatus?.robotBattery).toFixed(0)}%</p> 
                    </div> 
                      <SignalCellularAltIcon style={{color:'white' , margin:'7px', marginTop:'0px'}}/>
                      {playbtn ? <PlayCircleIcon onClick={()=>handlePlayIcon(robotData?.robotStatus?.robotId)} className="play-icon p-color-red"/>:<PauseCircleIcon className="play-icon p-color-green" onClick={()=>handlePauseIcon(robotData?.robotStatus?.robotId)}/>}
                  </div> 
              </div>
           </div>
           <div className="dashboard-left-side-div-two-wrapper">
              {/* <h5 className='dashboard-left-side-div-two-wrapper-h5'> <span><LocationOnOutlinedIcon style={{color:'#b1b1b1'}}/> </span>Map</h5> */}
                  <div className='dashboard_page_Map_heading_wrapper'>
                  <h5 className='dashboard-left-side-div-two-wrapper-h5'><span><LocationOnOutlinedIcon style={{color:'#b1b1b1'}}/> </span>Map</h5>
                  {/* <FormControlLabel
                    control={<IOSSwitch  checked={googleMap} onChange={()=>{setgoogleMap(!googleMap)}} name=""/>}
                    label=""
                  /> */}
                 </div>                  
            {/* {googleMap ?                  */}
              <div className='dashboard-left-side-div-two-Map-Container-one'>  
                 <MapLeaflet robotId={robotId} fleetId={fleetId} latitude={robotID.state.latitude} longitude={robotID.state.longitude}/>
             </div> 
           {/* : <div className='dashboard-left-side-div-two-Map-Container-one'>
             <Maps robotId={robotData?.robotStatus?.robotId} fleetId={fleetId}/></div>} */}
         </div>
         <div className="dashboard-left-side-div-three-wrapper">
              <h5 className='dashboard-left-side-div-two-wrapper-h5'> <span><MeetingRoomOutlinedIcon style={{color:'#b1b1b1'}}/> </span>Robot Controls</h5>
              <div className='dashboard-left-side-div-two-robot-button-Container d-flex-center'>  
               {robotData?.robotStatus?.door1Status==="CLOSE" ? <button onClick={()=>handleOpendoorone(robotData?.robotStatus?.robotId)}> Open Door 1 </button> :<button onClick={()=>handleClosedoorone(robotData?.robotStatus?.robotId)}> Close Door 1 </button>} 
               {robotData?.robotStatus?.door2Status==="CLOSE" ? <button onClick={()=>handleOpendoortwo(robotData?.robotStatus?.robotId)}> Open Door 2 </button> :<button onClick={()=>handleClosedoortwo(robotData?.robotStatus?.robotId)}> Close Door 2 </button>} 
             </div> 
        </div>
        <div className="dashboard-left-side-div-four-wrapper">
              <h5 className='dashboard-left-side-div-two-wrapper-h5'> <span><SettingsOutlinedIcon style={{color:'#b1b1b1'}}/> </span>Robot Actions</h5>
             <div className='dashboard-left-side-div-two-Map-Container-two'>  
               <RobotActions robotId={robotData?.robotStatus?.robotId}/>
             </div> 
         </div>
     </div>
      <div className='col-md-9 dashboard-right-side-div-wrapper-Main' style={{padding:'0px'}}> 
      
      
      <iframe src="https://app.formant.io/devices/1fd76897-ddfb-4997-b2f9-08bde767e4bf" width="100%" height="100%" frameborder="0"></iframe>
     </div>
    </div> 
   <div className="Dashboard-Page-bottom-div-Main-wrapper d-flex-center"> 
     
      <button onClick={()=>handlesendHome(robotData?.robotStatus?.robotId)}>Send Home </button>
        {/* {viewerState ? <button onClick={()=>handleStopViewer(robotData?.robotStatus?.robotId)}>Stop Viewer </button> :<button onClick={()=>handleStartViewer(robotData?.robotStatus?.robotId)}>Start Viewer </button> } */}
        <button onClick={()=>handleResetDiagnostics(robotData?.robotStatus?.robotId)}>Reset Diagnostics </button>
        <DisengageMotorsModal robotId={robotData?.robotStatus?.robotId} robotData={robotData}/>
        <OpenSSHModal robotId={robotId} fleetId={fleetId}/>  
        <AutorunMoving robotId={robotId}/> 
        <TeleopCompleted robotId={robotId}/>
         

         {isstop ?<button className="Stop-button-dashhboard-page" onClick={()=>handleRelease(robotData?.robotStatus?.robotId)}> Release </button> :<button className="Stop-button-dashhboard-page" onClick={()=>handleStop(robotData?.robotStatus?.robotId)}> E-STOP </button> }  
       <div> 
         <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p'> Total Distance Covered </p> <span className="Dashboard-Page-bottom-div-Main-wrapper-content-span"> {robotData?.robotStatus?.totalDistanceTravelled.toFixed(2)} miles</span>  
      </div>
      <div> 
        {/* <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p'> Testing Mode</p>
        <FormControlLabel
        control={ <IOSSwitch checked={state.testingmode} onChange={handletestingChange} name="testingmode"/>}
        label=""
        /> */}
     
     </div>
     <div> 
        <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p'> Wander Mode</p>
        <FormControlLabel
        control={<IOSSwitch  checked={state.wandermode} onChange={handlewanderChange} name="wandermode"/>}
        label=""
        />
    </div>
    <div> 
        <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p'> Ring Light</p>
        <FormControlLabel
        control={<IOSSwitch  checked={state.ringlight} onChange={handleringlightChange} name="ringlight"/>}
        label=""
        />
    </div>
    <div> 
        <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p'> Head Light</p>
        <FormControlLabel
        control={<IOSSwitch  checked={state.headlight} onChange={handleheadlightchange} name="headlight"/>}
        label=""
        />
    </div>
     <div> 
     <PowerSettingsNewIcon style={{color:'#b1b1b1', cursor:'pointer'}} onClick={handlelogout} className="Dashboard-Page-Power-button"/>
    </div> 
  </div> 
</div>
<ToastContainer/>
  </>
  )
}

export default React.memo(Dashboard)