import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLocationList, getMapLocationList, getrobotStatus } from '../API';
import { latitudeaction, logitudeaction } from '../redux/actions';
import MapMarker from './MapMarker';

function MapLeaflet(props) {

    const [locationList, setlocationlist]=useState([])
    const [newlocationList, setnewlocationlist]= useState([])
    const positionarray=[
        [53.506,-0.09],
        [59,-1],
        [60, -20]
    ]
    const dispatch = useDispatch()
    const [position, setposition] = useState([0])
    const token = localStorage.getItem('token')
    const positiontwo =[25,35]
    var latitude =25
    var longitude =35
    
    //  function newlatlong(){
    //   var newLatLng = new L.LatLng(locationList?.robotStatus?.latitude, locationList?.robotStatus?.longitude);
    //     marker.setLatLng(newLatLng); 
    //     console.log('INdeis Newlatlong',marker,newLatLng)
    //   }
      

    useEffect(()=>{
      setInterval(()=>{
        getrobotStatus(props.robotId,token).then((res)=>{
          // console.log('GetRobot Status Response',res)
          // let locationli=res.data.data
          // console.log('res?.data?.data',locationli)
          if(res?.data?.status===200 ||res?.data?.status ==='200' ){
            setlocationlist(res.data.data)
          }      
          else {
            return null
          }    
       
         }).catch((err)=>{
            console.log('GetRobot Status Error', err)
      })
      // newlatlong()
      
     },10000)  
     getLocationList(props.fleetId,token).
     then((res)=>{
        // console.log('Location list array Response', res)
        setnewlocationlist(res.data.data)
     })
     .catch((err)=>{
        console.log('Location list array Error', err)
     })
    },[])

    return(
    <div>
       {(()=>{ 
          latitude=locationList?.robotStatus?.latitude
          longitude=locationList?.robotStatus?.longitude
        })()}

       {locationList?.robotStatus?.latitude && locationList?.robotStatus?.longitude >0 ? <MapMarker  data={locationList} latitude={latitude} longitude={longitude}  initiallatitude={props.latitude} initiallongitude={props.longitude} locationData={newlocationList}/> :null}
  
    </div>
  )
}

export default React.memo(MapLeaflet)