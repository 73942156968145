import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getrobotStatus, handleteleopAckinterrupt } from '../API'

function TeleopAck(props) {
   

    const [ackdisabled, setackdisabled] =useState(false)
  
    const token= localStorage.getItem('token')  
     useEffect(()=>{
        getrobotStatus(props.robotId,token)
        .then((res)=>{
          console.log('Robot Status Teleop Completed  Response',res)
          if(res?.data?.data?.robotStatus?.robotRunningState==='TELE_OP_MOVING'){
            setackdisabled(true)
          }
          else{
            // setackdisabled(true)
          }
        })
        .catch((err)=>{
            // console.log('Robot Status Open Ssh Error',err)
        })
},[])
    return (
    <>
    
   
 </> )
}

export default TeleopAck