import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getrobotStatus, handleteleopAckinterrupt, handleteleopComplete } from '../API'


function TeleopCompleted(props) {
    
    const TeleOpCompletedError=()=> toast.error('TeleOp Completed Failed')
    const TeleOpCompletedSuccess=()=>toast.success('Teleop Completed Successfully')
    const TeleOpAckError=()=> toast.error('TeleOp Acknowledge Failed')
    const TeleOpSuccess=()=>toast.success('Teleop Acknowledge Successfully')
    const [completeddisabled, setcompleteddisabled] =useState(true)
    const [completeddisabledinner, setcompleteddisabledinner] =useState(false)
    const [lastcheck, setlastcheck] =useState(true)
    const token = localStorage.getItem('token')
    useEffect(()=>{
       setInterval(()=>{
        getrobotStatus(props.robotId,token)
        .then((res)=>{
          // console.log('res?.data?.data?.robotStatus?.teleop',res?.data?.data?.robotStatus?.teleop)
          // console.log('Robot Status Teleop Completed  Response',res?.data?.data?.robotStatus?.robotRunningState)
          if((res?.data?.data?.robotStatus?.robotRunningState==='TELE_OP_MOVING' || res?.data?.data?.robotStatus?.robotRunningState==='TELE_OP' || res?.data?.data?.robotStatus?.robotRunningState==='TELE_OP_OBSTACLE') && res?.data?.data?.robotStatus?.teleop==="YES"){
            setlastcheck(false)
            setcompleteddisabled(false)
          }
          else if((res?.data?.data?.robotStatus?.robotRunningState==='AUTO_RUN_MOVING' || res?.data?.data?.robotStatus?.robotRunningState==='AUTO_RUN'|| res?.data?.data?.robotStatus?.robotRunningState==='AUTO_OBSTACLE' || res?.data?.data?.robotStatus?.robotRunningState==='MANUAL') && res?.data?.data?.robotStatus?.teleop==="YES"){
            // setcompleteddisabled(true)
            setcompleteddisabled(true)
            setlastcheck(false)
          }
          else if(res?.data?.data?.robotStatus?.teleop===null || res?.data?.data?.robotStatus?.teleop===undefined) {
            setlastcheck(true)
            // console.log('sfsffsbdfbfbfbdfbdfbdbfbf')
          }    
          else{
            setlastcheck(true)
          }
          
        })
        .catch((err)=>{
            // console.log('Robot Status Open Ssh Error',err)
        })
       },5000)
        
},[])
const handleteleopAckfunction=(robotId)=>{
    handleteleopAckinterrupt(robotId).then((res)=>{
      TeleOpSuccess() 
      setcompleteddisabled(true)
      setlastcheck(true)
    })
    .catch((err)=>{
      TeleOpAckError()
      })
  }
    const handleteleopCompletefunction=(robotId)=>{
        handleteleopComplete(robotId).then((res)=>{
          TeleOpCompletedSuccess()
          setcompleteddisabledinner(true)
        })
        .catch((err)=>{
          TeleOpCompletedError()
        })
      }

  return (
    <>
   
    {lastcheck ? null :
    <>  {completeddisabled ? 
        <button className={"Dashboard-page-teleop-button" } onClick={()=>handleteleopAckfunction(props?.robotId)}>Teleop Ack</button>  
        :<button  className={!completeddisabled ? "Dashboard-page-teleop-button":"Dashboard-page-teleop-button-disabled" } onClick={()=>handleteleopCompletefunction(props?.robotId)}> Teleop Completed</button>}</>
    }
  
    </>
  )
}

export default TeleopCompleted