import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { DisengageMotors } from '../API';

function DisengageMotorsModal(props) {
    const [open, setOpen] = React.useState(false);
    const [IsOpen, setisOpen] =useState()
    const handleOpen = () => {
      setOpen(true);
    }
    const DisengageError=()=> toast.error('Disengage Failed')
    const DisengageSuccess=()=>toast.success('Engage Success')
    useEffect(()=>{
    if(props?.robotStatus?.robotRunningState==="MANUAL"){
      setisOpen(true)
    }  
    else{
      setisOpen(false)
    } 
    },[])
    
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 700,
      bgcolor: '#282F42',
      p: 4,
      borderRadius: "11px",
      boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)"
    };
    const token = localStorage.getItem('token')
    const handleClose = (reason) => {
        if (reason && reason == "backdropClick") 
            return;
         setOpen(false);
      
      }
      const handleDisengageMotors=(robotId, State)=>{
        
        DisengageMotors(robotId, token,State)
        .then((res)=>{
            // console.log('Disengage Motors Response', res)
            DisengageSuccess()
            setisOpen(!IsOpen)
        })
        .catch((err)=>{
            // console.log('Disengage Motors Response', err)
            DisengageError()
        })
        handleClose()
      } 

  return (
     <div>
      {IsOpen ? <> <button onClick={handleOpen}>Engage Motors </button>
      <Modal
        backdrop="static"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
         <p className='Modal-wrapper-p'> 
           Do you want to Engage the Motors?
         </p>
        <div className='Modal-buttons-wrapper d-flex-center-center'> 
          <button className="Modal-btn" onClick={handleClose}> No</button> 
          <button className="Modal-btn Modal-btn-red" onClick={()=>handleDisengageMotors(props.robotId , 'ENGAGE_MOTORS')}> Yes</button>
         </div>
        </Box>
      </Modal></>:
      <>
       <button onClick={handleOpen}>Disengage Motors </button>
       <Modal
         backdrop="static"
         open={open}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description">
         <Box sx={style}>
          <p className='Modal-wrapper-p'> 
            Do you want to Disengage the Motors ?
          </p>
         <div className='Modal-buttons-wrapper d-flex-center-center'> 
           <button className="Modal-btn" onClick={handleClose}> No</button> 
           <button className="Modal-btn Modal-btn-red" onClick={()=>handleDisengageMotors(props.robotId , 'DISENGAGE_MOTORS')}> Yes</button>
          </div>
         </Box>
       </Modal>
       </>
      }
    </div>
  )
}

export default DisengageMotorsModal