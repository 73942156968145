import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFleetList, robotListfleetId } from '../API/index'
import { FleetData, fleetlatitudeaction, fleetlogitudeaction, robotDataAction } from '../redux/actions'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Battery80Icon from '@mui/icons-material/Battery80';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Header from './Header'
import IndividualRobot from './IndividualRobot'


function SelectFleet() {
 
 const dispatch= useDispatch() 
 const [fleetlist, setfleetlist] =useState() 
 const [robotlist, setrobotlist] =useState()
 const [isrobot, setisrobot] = useState(true)
 const [robotmsg, setrobotmsg] =useState([])
 const navigate= useNavigate()
 const email = localStorage.getItem('useremail')
 const isLogged=localStorage.getItem('Isloggedin')
 const token = localStorage.getItem('token')

 const [isdata, setisdata] =useState(true)
 const wholeData = useLocation()
 const robotMsgData = useSelector(state=>state.robotMessageReducer)
 
 useEffect(()=>{
    if(isLogged==='LoginFalse'){
      window.location.replace('/')
     }
    else{
      setfleetlist(wholeData?.state?.name?.fleet)
  }
  },[wholeData,robotMsgData])
// useEffect(()=>{
//   setInterval(()=>{
//     window.location.reload()
//   } , 10000)
// })

 const robotlistRedux=  useSelector(state=> state.robotlistReducer)
 const getrobotStatus=(item,data)=>{
     switch(item.robotStatus){
      case "AVAILABLE":
        return  <p className="text-left m-0 p-color-green">Available</p>
      case "UNAVAILABLE":
        return  <p className="text-left m-0 p-color-red"> UnAvailable</p>  
      case "CHARGING":
        return  <p className="text-left m-0 p-color-green">Charging</p>
      case "ERROR":
        robotmsg.push({
          fleetId:data?.fleetId,
          fleetName:data?.fleetName,
          robotStatus:item.robotStatus,
          robotId:item.robotId,
          robotBattery:item.robotBattery,
          latitude:data?.latitude,
          longitude:data?.longitude
        })
        return <p className="text-left m-0 p-color-red"> Error</p>
      case "Need Help":
        robotmsg.push({
          fleetId:data?.fleetId,
          fleetName:data?.fleetName,
          robotStatus:item.robotStatus,
          robotId:item.robotId,
          robotBattery:item.robotBattery,
          latitude:data?.latitude,
          longitude:data?.longitude
        })
       return <p className="text-left m-0 p-color-red"> Need Help</p>
      default:
       return <p className="text-left m-0 p-color-red"> {item.robotStatus}</p>
    }
 }
 const handlerobotdata=(robotdata)=>{
  //  console.log('RobotDatarobotdata',robotdata)
 //  dispatch(robotDataAction(robotdata[0]))
  localStorage.setItem('fleetId',robotdata?.fleetId)
  navigate(`/dashboard/${robotdata?.robotId}` ,{state:{name:robotdata}})
} 

  return (
    
    <>
   <Header/>
   {/* {console.log('fleetlist',fleetlist)} */}
    <div className='container-fluid SelectFleetPage-wrapper-Main'>
          {/* {console.log('wholeData',wholeData)}   */}
       <div>
      {robotMsgData?.robotmsgData?.length >0 ?<h5 className='Select-Page-Robot-Message-Heading'> ROBOT MESSAGE</h5> :null}
       <div className='SelectFleet-page-Error-box-wrapper'> 
        {robotMsgData?.robotmsgData?.map((data)=>{
         return <div className="SelectFleetPage-content-wrapper-Main border-red" onClick={()=>handlerobotdata(data)}> 
              <div className='border-blue-top SelectFleetPage-inner-li-div d-flex-center'> 
                <button className="Error-state-teleop-ack-button"> Teleop Ack</button>
                     <div>
                      <div> 
                       <h4 className="Select-page-fleetName"> {data.fleetName}</h4>
                    </div>
                    <div>
                        <p className="SelectFleetPage-inner-li-div-robot-id" style={{color:'white'}}>{data.robotId}</p>
                        {getrobotStatus(data, null)}
                   </div>
                   </div> 
                    <div className="dashboard-left-side-div-one-inner-div"> 
                         <div  className="dashboard-left-side-div-one-wrapper-battery-div">
                            <Battery80Icon style={{color:'rgb(113, 207, 98)' , transform:'rotate(90deg)', fontSize:'24px'}}/>
                            <p style={{fontSize:'10px'}}>{parseInt(data.robotBattery).toFixed(0)}%</p> 
                        </div> 
                          <SignalCellularAltIcon style={{color:'white' , margin:'7px', marginTop:'0px'}}/>
                       {data.robotStatus==="AVAILABLE" ? <PauseCircleIcon className="play-icon p-color-green"/>:<PlayCircleIcon className="play-icon p-color-red"/>}
                  </div> 
             </div> 
          </div>
          })}
      </div>     
      </div>  
      <div className="SelectFleetPage-content-wrapper-Main">   
           {fleetlist?.map((data)=>{
           {console.log('fleetlist',fleetlist)}
               return (
                <div style={{width:'100%',marginTop:'20px'}}>
                    <h3 className='Dashboard-content-div-one-left-side-h3'>{data.fleetName}</h3>
                    <IndividualRobot data={data} latitude={data?.latitude} longitude={data?.longitude} />
               </div>
              ) })} 
      </div> 
 
    </div>
    </>  
  )
}

export default React.memo(SelectFleet)